import { createBrowserClient } from '@supabase/ssr';
import { useMemo } from 'react';
import { publicEnv } from '@/config/public-env.config.mjs';
import type { Database } from './database.types';
import type { TypedSupabaseClient } from './types';

// export function createClient() {
//   // Create a supabase client on the browser with project's credentials
//   return createBrowserClient<Database>(
//     publicEnv.NEXT_PUBLIC_SUPABASE_URL,
//     publicEnv.NEXT_PUBLIC_SUPABASE_ANON_KEY
//   );
// }

let client: TypedSupabaseClient | undefined;

function getSupabaseBrowserClient() {
  if (client) {
    return client;
  }

  client = createBrowserClient<Database>(
    publicEnv.NEXT_PUBLIC_SUPABASE_URL,
    publicEnv.NEXT_PUBLIC_SUPABASE_ANON_KEY
  );

  return client;
}

function useSupabaseBrowser() {
  return useMemo(getSupabaseBrowserClient, []);
}

export default useSupabaseBrowser;
