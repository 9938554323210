'use client';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@blackavocado/ui/components/ui/select';
import { useLocale, useTranslations } from 'next-intl';
import { type DetailedHTMLProps, useTransition } from 'react';
import { usePathname, useRouter } from '@/navigation';

type LanguageToggleProps = DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export function LanguageToggle(props: LanguageToggleProps) {
  const tI18n = useTranslations('system.i18n');
  const curLocale = useLocale();
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();

  function handleSelectChange(locale: string) {
    startTransition(() => {
      router.replace(pathname, { locale });
    });
  }

  return (
    <Select
      value={curLocale}
      onValueChange={handleSelectChange}
      disabled={isPending}
    >
      <SelectTrigger className="w-auto min-w-32 gap-1" {...props}>
        <SelectValue placeholder={tI18n('selectALanguage')} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {/* <SelectLabel>{tI18n('language')}</SelectLabel> */}
          <SelectItem value="en">{tI18n('en')}</SelectItem>
          <SelectItem value="vi">{tI18n('vi')}</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
