'use client';

import {
  Button,
  type ButtonProps,
} from '@blackavocado/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@blackavocado/ui/components/ui/dropdown-menu';
import { Moon, Sun } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { useTheme } from 'next-themes';
import { toast } from 'sonner';

export function ThemeModeToggle(props: ButtonProps) {
  const { setTheme, theme } = useTheme();
  const tSystem = useTranslations('system');

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" {...props}>
          <Sun className="size-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute size-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuCheckboxItem
          checked={theme === 'light'}
          onClick={() => {
            setTheme('light');
            toast(
              tSystem('theme.switched_to', { theme: tSystem('theme.light') })
            );
          }}
        >
          {tSystem('theme.light')}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={theme === 'dark'}
          onClick={() => {
            setTheme('dark');
            toast(
              tSystem('theme.switched_to', { theme: tSystem('theme.dark') })
            );
          }}
        >
          {tSystem('theme.dark')}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={theme === 'system'}
          onClick={() => {
            setTheme('system');
            toast(
              tSystem('theme.switched_to', { theme: tSystem('theme.system') })
            );
          }}
        >
          {tSystem('theme.system')}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
