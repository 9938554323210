import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/blackavocado/src/app/[locale]/(auth)/account/account-btn.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageToggle"] */ "/vercel/path0/apps/blackavocado/src/components/language-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeModeToggle"] */ "/vercel/path0/apps/blackavocado/src/components/theme-mode-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
