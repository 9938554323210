'use client';

import { Button } from '@blackavocado/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@blackavocado/ui/components/ui/dropdown-menu';
import { Skeleton } from '@blackavocado/ui/components/ui/skeleton';
import { useQuery } from '@tanstack/react-query';
import { LogOut, UserIcon } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';
import { ROUTES } from '@/config/router';
import { Link } from '@/navigation';
import useSupabaseBrowser from '@/utils/supabase/client';

export default function AccountBtn() {
  const tAuth = useTranslations('auth');
  const supabase = useSupabaseBrowser();
  const { data, isLoading } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      return supabase.auth.getUser();
    },
  });

  const user = useMemo(() => data?.data.user, [data]);

  if (isLoading) {
    <Skeleton className="h-10 w-24" />;
  }

  if (!user) {
    return (
      <Button variant="secondary" asChild>
        <Link href={ROUTES.auth.login}>{tAuth('login')}</Link>
      </Button>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <Link href={ROUTES.auth.account}>
            Hi, <strong>{user.email}</strong>
          </Link>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem>
          <Link
            href={ROUTES.auth.account}
            className="flex flex-row items-center"
          >
            <UserIcon className="mr-2 size-4" />
            <span>{tAuth('account')}</span>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <form action="/auth/signout" method="post">
            <button className="flex flex-row items-center" type="submit">
              <LogOut className="mr-2 size-4" />
              <span>{tAuth('signOut')}</span>
            </button>
          </form>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
